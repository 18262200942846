import request from './request.component';

function get() {
    return request({
        url: `users/18747307923/owned_events/`,
        method: 'GET',
        params: {
            token: "PNMGHC2FP4BPS5U3BXT2"
        }
    });
}

function getNewProvider() {
    return request({
        url: `https://api.prod.locarius.io/v1/data/1820/events/`,
        method: 'GET',
        params: {
            token: "54Y0FsqKowhayVRSZ10a9Exg73bsqIcg"
        }
    });
}

function getEventsByOrganization(options) {
    return request({
        // 287908993480  - organization ID
        url: `organizations/287908993480/events/?status=live&time_filter=current_future&` + options,
        method: 'GET',
        params: {
            token: "PNMGHC2FP4BPS5U3BXT2"
        }
    });
}

function getEventsByVenue() {
    return request({
        // 30775739  - current venue ID
        // 36100789  - old venue ID
        // 30775739  - old venue id
        url: `venues/30775739/events/`,
        method: 'GET',
        params: {
            token: "PNMGHC2FP4BPS5U3BXT2",
            only_public: true,
            status: 'live'
        }
    });
}

//, update, delete, etc. ...
const API = {
    get,
    getNewProvider,
    getEventsByOrganization,
    getEventsByVenue
}

export default API;