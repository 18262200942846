import React, {useState, useEffect, Suspense} from "react";
import ReactGA from 'react-ga';
import ReactDOM from "react-dom";
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import {
  StoreProvider
} from "./redux/store";

import API from './data/api.component.js';

ReactGA.initialize('G-4F6JJEZCFW');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = React.lazy(() => import( /* webpackChunkName: "App" */ "./App"));
const AboutUs = React.lazy(() => import( /* webpackChunkName: "AboutUs" */ "./AboutUs"));
const ContactUs = React.lazy(() => import( /* webpackChunkName: "ContactUs" */ "./ContactUs"));
const HostEvent = React.lazy(() => import( /* webpackChunkName: "HostEvent" */ "./HostEvent"));
const TrailsideTips = React.lazy(() => import( /* webpackChunkName: "TrailsideTips" */ "./TrailsideTips"));

function combineArray(array1, array2) {
  return [].concat(array1, array2);
}

function useFetchEvents() {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    // You can await here
    const response = await API.getEventsByOrganization("continuation=");
    const responseV2 = await API.getNewProvider();
    
    let newEventProvider = [];
    if (responseV2.body.result) {
      newEventProvider = responseV2.body.result;
    }

    let resp = [];
    if(response.pagination.has_more_items) {
        resp = await API.getEventsByOrganization("continuation=" + response.pagination.continuation);
    }
    setData(response)
    if(resp) {
      setData2(resp)
    }
    if(newEventProvider) {
      setData3(newEventProvider)
    }
    setLoading(false);
    // ...
  }
  useEffect(() => {
    fetchData();
  }, []);

  return [data, data2, data3, loading];
}

function Root() {

  const [data, data2, data3, loading] = useFetchEvents();
  let newData = [];
  if (data3) {
    newData = combineArray(data.events, data3)
  } else if (data2.events) {
    newData = combineArray(data.events, data2.events)
  } else {
    newData = data.events
  }

  return ( 
    <Router>
      <StoreProvider>
        <Suspense fallback = {
          <div className = "loading" / >
        } >
          <Switch>
            <Route exact path = "/" >
              <App data = {
                newData
              }
              loading = {
                loading
              }
              /> 
            </Route > 
            <Route exact path = "/about-us" >
              <AboutUs />
            </Route> 
            <Route exact path = "/contact-us" >
              <ContactUs />
            </Route> 
            <Route exact path = "/host-an-event" >
              <HostEvent />
            </Route> 
            <Route exact path = "/trailside-tips" >
              <TrailsideTips />
            </Route> 
          </Switch > 
        </Suspense> 
      </StoreProvider > 
    </Router>
  )
}

ReactDOM.render( < Root / > , document.getElementById('root'));
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();